// firebaseConfig.js
// Contains the config details of firebase, this should NOT be committed to your repo
// if the repo is public
// You could optionally use .env file to store these data

const firebaseConfig =  {
    apiKey: "AIzaSyCoCdry7IyfYb1GWyFxioLQjM_O211fTKc",
    authDomain: "pinpointsurf.firebaseapp.com",
    databaseURL: "https://pinpointsurf-default-rtdb.firebaseio.com",
    projectId: "pinpointsurf",
    storageBucket: "pinpointsurf.appspot.com",
    messagingSenderId: "191948200088",
    appId: "1:191948200088:web:8dab8e728622f5076c18ed",
    measurementId: "G-BPM3CT7H32"
};

export default firebaseConfig