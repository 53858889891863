import React from "react";
import suspenseImage from '../../assets/pps_blue_small.png';

//styles are inline so that they load as soon as the component renders.

const Loader = (props) => {
    return (
        <div style={{
            backgroundColor: '#9CA3AF',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }} >
            <div style={{
                marginLeft: '2rem',
                marginRight: '2rem'
            }}>
            <img src={`${suspenseImage}`} alt=""></img>
            </div>
        </div>
    );
}
 
export default Loader;