import React from "react";
import "./index.css";
import 'mapbox-gl/dist/mapbox-gl.css';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { render, hydrate } from "react-dom";

const rootElement = document.getElementById("root");

// This should allow googlebot to receive rendered html for fast site scans, 
// and still be able to render the page when it visits with JS enabled.
if (!/MSIE|Trident/.test(window.navigator.userAgent) && !rootElement.hasChildNodes()) {
    console.log("rendering");
    render(<React.StrictMode><App /></React.StrictMode>, rootElement);
}
else {
    console.log("Rehydrating");
    hydrate(<React.StrictMode><App /></React.StrictMode>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
