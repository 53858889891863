import { createTheme } from "@mui/material/styles";
import ppsColors from './ppsColors';


const theme = createTheme({
	palette: {
		primary: {
			main: ppsColors.primary,
		},
        secondary: {
            main: ppsColors.secondary
        }
	},
	components: {
		MuiDrawer: {
			root: {
				backgroundColor: ppsColors.backgroundGrey
			}
		}
	}
});

export default theme;