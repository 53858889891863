import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig"

class Fire {
	constructor() {
		this.app = undefined;
	}

	initialize() {
		try {
			this.app = initializeApp(firebaseConfig);
		} catch (err) {
			if (!/already exists/.test(err.message)) {
				console.error("Firebase initialization error", err.stack);
			}
		}
		console.log("initialized firebase")
	}
	disconnect() {
		this.app = null;
	}
}

const instance = new Fire();
export default instance;
