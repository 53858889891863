import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Fire from "./services/firebaseInit";
//import { getAnalytics, logEvent } from "firebase/analytics";
import { getAnalytics } from "firebase/analytics";
import { HelmetProvider } from "react-helmet-async";
import Loader from "./components/SuspenseFB/SuspenseFB";
import { ThemeProvider } from "@mui/material/styles";
import theme from './theme/theme';

// Component imports
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Admin = lazy(() => import("./pages/Admin/Admin"));
const Blog = lazy(() => import("./pages/Blog/Blog"));
const About = lazy(() => import("./pages/About/About"));
const Privacy = lazy(() => import("./pages/Privacy/Privacy"));
const Terms = lazy(() => import("./pages/Terms/Terms"));
const Support = lazy(() => import("./pages/Support/Support"));
const InfoPage = lazy(() => import("./components/InfoPage/InfoPage"));
const Spots = lazy(() => import("./pages/Spots/Spots"));



const App = () => {
	useEffect(() => {
		Fire.initialize();
		if (process.env.NODE_ENV === "production") {
			console.log("Production Env");
			getAnalytics(Fire.app);
		} else {
			console.log("Development Env");
		}
	});

	const handleLogEvent = (event) => {
		// if (process.env.NODE_ENV==="production") {
		// 	logEvent(analytics, event.type,  //'exception' {'description:'bad thing','fatal':False}
		// 		event.eventObject
		// 	  );
		// }
	};

	return (
		<HelmetProvider>
			<Fragment>
				<ThemeProvider theme={theme}>
					<div className="h-full">
						<Router>
							<Suspense fallback={<Loader />}>
								<Switch>
									<Route path="/about">
										<InfoPage title="About">
											<About />
										</InfoPage>
									</Route>
									<Route path="/privacy">
										<InfoPage title="Privacy">
											<Privacy />
										</InfoPage>
									</Route>
									<Route path="/support">
										<InfoPage title="Support">
											<Support />
										</InfoPage>
									</Route>
									<Route path="/terms">
										<InfoPage title="Terms">
											<Terms />
										</InfoPage>
									</Route>
									<Route path="/blog">
										<Blog />
									</Route>
									<Route path="/spots">
										<InfoPage title="All Spots">
											<Spots />
										</InfoPage>
									</Route>
									<Route path="/pps-admin-portal">
										<InfoPage
											title="Admin"
											handleLogEvent={handleLogEvent}
										>
											<Admin />
										</InfoPage>
									</Route>
										<Route path="/:lng/:lat/:zoom/:domain/:urlName?">
									<Dashboard />
									</Route>
									<Route path="/">
										<Dashboard />
									</Route>
								</Switch>
							</Suspense>
						</Router>
					</div>
				</ThemeProvider>
			</Fragment>
		</HelmetProvider>
	);
};

export default App;
